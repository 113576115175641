





















































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import PurchaseService from '@/services/PurchaseService'
import ProductService from '@/services/ProductService'
import moment from 'moment'
import { namespace } from 'vuex-class'
import OrderService from '@/services/OrderService'
const Auth = namespace('Auth')
const CartProductStoreEdit = namespace('CartProductStoreEdit')
const ModeCartEdit = namespace('ModeCartEdit')
const CartProductStore = namespace('CartProductStore')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component({
  components: {
    LayoutDefault
  }
})
export default class ProductList extends Vue {
  public moment = moment
  public purchaseOrderMonth: any = []
  public rows: number = 0
  public showPagination: boolean = false
  public orderEditId: any = 0
  public now = moment().format('YYYY-MM')
  private stockQuantity: any = 0
  public activeModalAddToCart: boolean = false
  public mode_purchase: any = 1
  public stillAddToCartAlthoughtPriceChange: boolean = false
  public productTempt: any

  private currentPage: number = 1
  private perPage: number = 10
  private purchaseFilter: any = []
  private purchaseSelected: any = ''
  private purchase: any = []

  @Auth.Getter
  public isLoggedIn!: boolean
  @CartProductStoreEdit.Action
  private CLEAR_CART_PRODUCT_EDIT!: () => void
  @ModeCartEdit.Action
  private SET_NEW_MODE_CART_EDIT!: (data: any) => any
  @CartProductStore.Action
  private removeAllCart!: () => any
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number
  @CartProductStoreEdit.Getter
  private getCartEdit!: any
  @CartProductStore.Getter
  private getCart!: any
  @CartProductStore.Action
  private addToCart!: (data: any) => any
  @CartProductStoreEdit.Action
  private addToCartEdit!: (data: any) => any

  created() {
    if (localStorage.getItem('order_id_edit')) {
      this.orderEditId = Number(
        JSON.parse(JSON.stringify(localStorage.getItem('order_id_edit')))
      )
    }
    this.fetchPurchase()
  }

  private async fetchPurchase() {
    if (localStorage.getItem('mode_purchases')) {
      this.mode_purchase = localStorage.getItem('mode_purchases')
    }

    let tour_mode = localStorage.getItem('tour_mode') ? true : false
    if (!tour_mode) {
      await PurchaseService.getPurchase(this.mode_purchase).then(
        (response: any) => {
          this.purchase = response.data.items
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          this.purchase =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )

      PurchaseService.getHistoryPurchase().then(
        (response: any) => {
          this.purchaseOrderMonth = response.data.items
          this.purchaseSelected = moment(this.purchaseOrderMonth[0]).format(
            'YYYY-MM'
          )
          this.handleChangePurchaseSelected()
        },
        (error: any) => {
          if (error.response.status === 429) {
            localStorage.clear()
            this.$bvModal.show(`too-many-request`)
          }
          this.purchaseOrderMonth =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )
    }
  }

  public handleChangePurchaseSelected() {
    this.purchaseFilter = this.purchase.filter(
      (purchase: any) =>
        moment(purchase.order_date).format('MM') ==
        moment(this.purchaseSelected).format('MM')
    )
  }

  get purchaseList() {
    if (this.purchaseSelected) {
      this.rows = this.purchaseFilter.length
      this.showPagination = this.purchaseFilter.length != 0

      return this.purchaseFilter.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    } else {
      this.rows = this.purchase.length
      this.showPagination = this.purchase.length != 0

      if (this.purchase.length > 0) {
        return this.purchase.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        )
      } else {
        return []
      }
    }
  }

  public goToOrderEdit(purchaseItem: any) {
    localStorage.removeItem('delivery_service_id_normal_merge')
    localStorage.removeItem('delivery_date_normal_merge')
    localStorage.removeItem('order_id_merge')
    localStorage.removeItem('cart_product')
    if (Number(localStorage.getItem('mode_purchases')) === 2) {
      this.$router.push({
        name: 'subscription-setting-detail',
        params: { order_id: purchaseItem.id }
      })
    } else {
      this.removeAllCart()
      this.CLEAR_CART_PRODUCT_EDIT()
      this.SET_NEW_MODE_CART_EDIT(true)
      this.$router.push({
        name: 'order-edit',
        params: { order_id: purchaseItem.id }
      })
    }
  }

  public async cancelOrder(purchaseItem: any) {
    await OrderService.cancelOrder(purchaseItem.id).then(
      (response: any) => {
        if (response) {
          this.fetchPurchase()
          this.$bvModal.hide(`bv-modal-cancel-order-${purchaseItem.id}`)
          this.$bvModal.show(`bv-modal-cancel-order-success`)
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status === 440) {
          if (error.response.data.message[0] === 'EDIT_DEADLINE_TIME_EXCEED') {
            this.$bvModal.show(`bv-modal-cancel-order-time-limit`)
          }

          if (error.response.data.message[0] === 'ORDER_ALREADY_CANCELED') {
            this.$bvModal.show(`bv-modal-order-already-cancel`)
          }
        } else {
          this.$bvModal.show(`bv-modal-cancel-order-error`)
        }
      }
    )
  }

  public async addToCartView(product: any) {
    this.productTempt = product

    let tour_mode =
      localStorage.getItem('tour_mode') &&
      localStorage.getItem('tour_mode') === 'on'
        ? true
        : false
    if (tour_mode || this.isLoggedIn === false) {
      this.$bvModal.show('modal-add-to-cart-redirect-tour-mode')
    } else {
      if (
        product.normal_price_excluding_tax >
          product.history_normal_price_base &&
        this.stillAddToCartAlthoughtPriceChange === false
      ) {
        this.$bvModal.show('modal-product-order-history')
      } else {
        await ProductService.getProductQuantity(product.id).then(
          (response: any) => {
            this.stockQuantity = response.data.items
          },
          (error: any) => {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
          }
        )
        let productToAdd = {
          id: product.id,
          name: product.product_name,
          product_name_display: product.product_name_display,
          img_url: product.img_url !== null ? product.img_url : null,
          stock_quantity: product.stock_quantity,
          quantity: 1,
          normal_price_excluding_tax: product.normal_price_excluding_tax,
          normal_price_including_tax: product.normal_price_including_tax,
          note: product.note,
          mode: this.getModeBuyProduct,
          skip: 0,
          tax_classification: product.tax_classification,
          temperature_control_name:
            product.temperature_control === null
              ? null
              : product.temperature_control.name,
          temperature_control_price:
            product.temperature_control === null
              ? null
              : product.temperature_control.price,
          temperature_control_id:
            product.temperature_control === null
              ? null
              : product.temperature_control.id
        }
        this.activeModalAddToCart = false
        let itemInCart = []
        if (localStorage.getItem('order_id_edit')) {
          itemInCart = this.getCartEdit.find((e: any) => e.id === product.id)
        } else {
          itemInCart = this.getCart.find((e: any) => e.id === product.id)
        }
        if (itemInCart && itemInCart.quantity > 99) {
          this.$toast(`一度に注文出来る最大個数は「100」`, {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          })
        } else {
          if (this.stockQuantity === 'unlimited') {
            this.checkAddOrder(productToAdd)
          } else {
            if (this.stockQuantity > 0) {
              let cartLength = 0
              let item = []
              if (localStorage.getItem('order_id_edit')) {
                cartLength = this.getCartEdit.length
                item = this.getCartEdit.find((e: any) => e.id === product.id)
              } else {
                cartLength = this.getCart.length
                item = this.getCart.find((e: any) => e.id === product.id)
              }
              if (cartLength > 0) {
                if (!item) {
                  this.checkAddOrder(productToAdd)
                }
                if (item && item.quantity < this.stockQuantity) {
                  this.checkAddOrder(productToAdd)
                } else if (item.quantity >= this.stockQuantity) {
                  if (this.stockQuantity === 0 || this.stockQuantity === null) {
                    this.$toast(`この商品は在庫がありません。`, {
                      timeout: 5000,
                      closeOnClick: true,
                      pauseOnFocusLoss: true,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.6,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: false,
                      rtl: false
                    })
                  } else {
                    this.$toast(
                      `この商品は` + this.stockQuantity + `個のみが残ります。`,
                      {
                        timeout: 5000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: false,
                        rtl: false
                      }
                    )
                  }
                }
              } else {
                this.checkAddOrder(productToAdd)
              }
            } else {
              if (this.stockQuantity === 0 || this.stockQuantity === null) {
                this.$toast(`この商品は在庫がありません。`, {
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: false,
                  rtl: false
                })
              } else {
                this.$toast(
                  `この商品は` + this.stockQuantity + `個のみが残ります。`,
                  {
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: false,
                    rtl: false
                  }
                )
              }
            }
          }
        }
        this.stillAddToCartAlthoughtPriceChange = false
      }
    }
  }

  private checkAddOrder(productToAdd: any) {
    if (localStorage.getItem('order_id_edit')) {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCartEdit(productToAdd)
    } else {
      this.$bvModal.show(`modal-notice-add-to-cart-success`)
      this.addToCart(productToAdd)
    }
  }

  private cancelAddToCart() {
    this.$bvModal.hide('modal-product-order-history')
  }

  private confirmContinueAddToCart() {
    this.stillAddToCartAlthoughtPriceChange = true
    this.$bvModal.hide('modal-product-order-history')
    this.addToCartView(this.productTempt)
  }
}
